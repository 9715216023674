.welcome-content {
    /* padding-top: 20%; */
}

.welcome-heading {
    font-size: 3rem;
    margin-top: 20%;
}

.retail-logo {
    margin-left: 33%;
    width: 33%;
    margin-top: 10%;
}

.book-btn {
    margin-top: 25%;
}

.booking-modal {
    padding-top: 30vh;
    top: 0;
    --border-radius: 12px;
}