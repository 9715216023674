.logo-home {
    font-weight: bold;
    color: dimgrey;
}

.welcome-text {
    font-weight: 600;
    font-size: 2rem;
    color: #3a3a3a;
    margin-bottom: none;
}

.emoji {
    margin: 0px;
    padding-left: 20px;
}


.gradient-effect {
    margin-top: 20%;
    background: linear-gradient(to right, rgb(65, 107, 146) 20%, #5562da 30%, #586f91 70%, rgb(76, 85, 102) 80%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 200% auto;
    animation: textShine 10s ease-in-out infinite alternate;
}

@keyframes textShine {
    to {
        background-position: 200%;
    }
}