.booking-date{
    text-transform: capitalize;
}

.booking-details{
    margin-top: 10px!important;
    margin-bottom: 10px!important;
}

.booking-notes{
    color: gray;
    margin-top: 10px!important;
}