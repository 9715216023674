#business-page {
  .business-card {
    margin-top: -50px;
  }


  .business-header {
    height: 200px;
    width: 100%;
    top: 0;
    left: 0;
  }


  .new-booking-header {
    padding-left: 16px;
  }

  .booking-card-content {
    max-height: 50vh;
    overflow: scroll;
    width: 95vw;
  }

  ion-toolbar {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;

    --background: linear-gradient(0deg, transparent, black);
    --color: white;
  }

  ion-toolbar ion-back-button,
  ion-toolbar ion-button,
  ion-toolbar ion-menu-button {
    --color: white;
  }

  .about-header {
    position: relative;

    width: 100%;
    height: 30%;
  }

  .about-header .about-image {
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    opacity: 1;

    transition: opacity 500ms ease-in-out;
  }

  .about-info {
    position: absolute;
    margin-top: -10px;
    border-radius: 10px;
    background: var(--ion-background-color, #fff);
  }

  .about-info h3 {
    margin-top: 0;
  }

  .about-info ion-list {
    padding-top: 0;
  }

  .about-info p {
    line-height: 130%;

    color: var(--ion-color-dark);
  }

  .about-info ion-icon {
    margin-inline-end: 32px;
  }

  /*
     * iOS Only
     */

  .ios .about-info {
    --ion-padding: 19px;
  }

  .ios .about-info h3 {
    font-weight: 700;
  }

}

.confirm-modal {
  --background: #ffffff00;
  --width: 100%;
  --height: 100%;
  padding-top: 20%;
  --backdrop-opacity: 0.7;
}